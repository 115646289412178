require('./i18next')
require('./modals')

var path = window.location.pathname.slice(-1) == '/' ? window.location.pathname.substring(0, window.location.pathname.length - 1) : window.location.pathname


if (path === '' || path === '/') {
    require('./index.js')
}
if (path === '/app') {
    require('./menu.js')
    require('./presenter')
}
if (path === '/scan') {
    require('./scan')
}
if (path === '/upload') {
    require('./upload')
}
if (path === '/presenterfiles') {
    require('./presenterFiles')
}
if (path === '/timer') {
    require('./timer')
}
if (path === '/offline') {
    require('./offline')
}