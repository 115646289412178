const i18next = require('i18next')
const HttpBackend = require('i18next-http-backend')

var userLang = navigator.language || navigator.userLanguage;
userLang = userLang.substr(0, 2).toLowerCase()

const suffixKeys = [',', '.', '!', '&', ':']
const suffixes = suffixKeys

i18next
    .use(HttpBackend)
    .init({
        lng: userLang,
        cleanCode: true,
        fallbackLng: 'en',
        supportedLngs: ["de", "en"],
        backend: {
            loadPath: '/locales/{{lng}}.json'
        }
    }, (err, t) => {
        if (err) return console.error(err)

        const i18n = (keys) => {
            let translated
            keys.split('+').forEach(key => {
                if (suffixes.find(e => e == key) === undefined) {
                    translated = translated == undefined ? t(key) : translated + ' ' + t(key)
                } else {
                    translated = translated + key
                }
            });
            return translated
        }

        const i18nText = () => {
            document.querySelectorAll('[i18n-text]').forEach((node) => {
                node.innerHTML = i18n(node.getAttribute('i18n-text'))
            })
        }
        i18nText()

        const i18nTooltip = () => {
            document.querySelectorAll('[i18n-tooltip]').forEach((node) => {
                node.setAttribute('tooltip', i18n(node.getAttribute('i18n-tooltip')))
            })
        }
        i18nTooltip()

        const i18nPlaceholder = () => {
            document.querySelectorAll('[i18n-placeholder]').forEach((node) => {
                node.setAttribute('placeholder', i18n(node.getAttribute('i18n-placeholder')))
            })
        }
        i18nPlaceholder()

        const i18nTitle = () => {
            document.querySelectorAll('[i18n-title]').forEach((node) => {
                node.setAttribute('title', i18n(node.getAttribute('i18n-title')))
            })
        }
        i18nTitle()
    })


const i18n = (keys) => {
    let translated
    keys.split('+').forEach(key => {
        if (suffixes.find(e => e == key) === undefined) {
            translated = translated == undefined ? i18next.t(key) : translated + ' ' + i18next.t(key)
        } else {
            translated = translated + key
        }
    });
    return translated
}

module.exports = {
    i18next,
    i18n
}
