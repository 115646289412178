const { i18n } = require('./i18next')


document.querySelectorAll('[data-modal]').forEach(modalOpener => {
    modalOpener.addEventListener('click', () => {
        openModal(modalOpener.dataset.modal, modalOpener.dataset.modalname)
    })
})

var modal

if (document.getElementById('modal') != undefined) {
    modal = document.getElementById('modal')
    modalTitle = document.getElementById('modalTitle')
    modalContent = document.getElementById('modalContent')
}

const openModal = (modalSrc, modalName) => {
    modal = document.createElement('div')
    modal.classList.add('modal')
    document.body.appendChild(modal)

    let modalContainer = document.createElement('div')
    modalContainer.classList.add('modal-container')
    modal.appendChild(modalContainer)

    let modalHeader = document.createElement('div')
    modalHeader.classList.add('modal-header')
    modalContainer.appendChild(modalHeader)

    let modalTitle = document.createElement('div')
    modalTitle.classList.add('modal-title')
    modalTitle.innerText = i18n(modalName)
    modalHeader.appendChild(modalTitle)

    let modalClose = document.createElement('div')
    modalClose.classList.add('modal-close')
    modalClose.innerHTML = `<i class="fas fa-times"></i>`
    modalClose.addEventListener('click', () => closeModal())
    modalHeader.appendChild(modalClose)

    let modalContent = document.createElement('div')
    modalContent.classList.add('modal-content')
    modalContainer.appendChild(modalContent)

    let iframe = document.createElement('iframe')
    modalContent.appendChild(iframe)
    iframe.src = modalSrc

    setTimeout(() => {
        document.body.addEventListener('click', () => closeModal(), { once: true })
    }, 500)

}
const closeModal = () => {
    modal.remove()
}