const { i18n } = require('./i18next')
const QrScanner = require('qr-scanner')

const video = document.getElementById('qr-video');
const flashToggle = document.getElementById('flash-toggle');
const camQrResult = document.getElementById('cam-qr-result');


function setResult(label, result) {
    console.log(result.data);
    clearTimeout(label.highlightTimeout);
    label.highlightTimeout = setTimeout(() => label.style.color = 'inherit', 100);

    var queryparams = result.data.split('?')[1];
    var params = queryparams.split('&');
    var pair = null,
        data = {};

    params.forEach(function (d) {
        pair = d.split('=');
        data[pair[0]] = pair[1];
    });

    if (data.t != null) {
        var token = data.t;
    }

    if (token) {
        label.textContent = token;
        window.location.href = '/?t=' + token
    }
}

const scanner = new QrScanner(video, result => setResult(camQrResult, result), {
    onDecodeError: error => {
        camQrResult.textContent = i18n('tokenNotFound')
        camQrResult.style.color = 'inherit';
    },
    highlightScanRegion: true,
    highlightCodeOutline: true,
    setInversionMode: 'original'
});

const updateFlashAvailability = () => {
    scanner.hasFlash().then(hasFlash => {
        flashToggle.classList.toggle(hasFlash, 'hide');
    });
};
window.scanner = scanner;

flashToggle.addEventListener('click', () => {
    scanner.toggleFlash().then(() => flashToggle.classList.toggle(scanner.isFlashOn(), 'active'))
});


scanner.start().then(() => {
    updateFlashAvailability();
}).catch(e => {

    if (e == 'Camera not found.') {
        document.getElementById('scannerContainer').classList.add('hide')
        document.getElementById('cameraNotSupported').innerHTML = `<h1>${i18n('cameraNotFound+!')}</h1><br><p>${i18n('cameraAllow')}</p> <p>${i18n('cameraNotPresent')}</p>`
    }
})


document.getElementById('btnBack').addEventListener('click', () => {
    window.location.href = '/'
})
