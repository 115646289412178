const { api, apiUpload, apiDownload } = require('./api')
const { i18n } = require('./i18next')

Number.prototype.round = function (places) {
    return +(Math.round(this + "e+" + places) + "e-" + places);
}

const drawTable = async () => {
    let apiRes = await api({ uri: 'data', task: 'getFiles' })
    let files = apiRes.data

    document.getElementById('presentations').classList.toggle('hide', files.length == 0)

    let template = document.getElementById('filesTemplate')
    let td = template.content.querySelectorAll('td')
    let tb = document.getElementsByTagName('tbody')

    const clearTable = () => {
        while (tb[0].firstChild) {
            tb[0].removeChild(tb[0].firstChild)
        }
    }
    clearTable()


    if (files.length > 0) {
        let i = 0
        files.forEach(file => {
            let uploadTime = new Date(file.uploadTime)

            let filesize = file.size / 1024 > 1000 ? (file.size / 1024 / 1000).round(2) + ' MB' : (file.size / 1024).round(2) + ' KB'

            td[0].textContent = file.filename
            td[1].textContent = filesize
            td[2].textContent = uploadTime.toLocaleString('de')
            td[3].querySelector('[data-ctrl="download"]').dataset.hash = file.hash
            td[3].querySelector('[data-ctrl="delete"]').dataset.hash = file.hash
            let clone = document.importNode(template.content, true)
            tb[0].appendChild(clone)
            i++
        })

        document.querySelectorAll('[data-ctrl="download"]').forEach(file => {
            file.addEventListener('click', () => {
                downloadFile(file.dataset.hash)
            })
        })
        document.querySelectorAll('[data-ctrl="delete"]').forEach(file => {
            file.addEventListener('click', () => {
                deleteFile(file.dataset.hash)

            })
        })


    }

}
drawTable()

const downloadFile = async (fileHash) => {
    await apiDownload({ uri: 'download/' + fileHash })
}
const deleteFile = async (fileHash) => {
    await api({ uri: 'data', task: 'deleteFile', hash: fileHash })
    window.top.postMessage('fileDeleted', '*')
    drawTable()
}


const file = document.getElementById('file')
file.addEventListener('change', () => { handleFiles(file.files) })


const dropArea = document.getElementById('dropArea')

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
    })

function preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
}

;['dragenter', 'dragover'].forEach(eventName => {
    dropArea.addEventListener(eventName, highlight, false)
})

    ;['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
    })

function highlight(e) {
    dropArea.classList.add('highlight')
}

function unhighlight(e) {
    dropArea.classList.remove('highlight')
}

dropArea.addEventListener('drop', handleDrop, false)

function handleDrop(e) {
    let dt = e.dataTransfer
    let files = dt.files

    handleFiles(files)
}

var fileLength
var fileToUpload
var uploadedFiles
var progressBar

function handleFiles(files) {
    uploadedFiles = 0
    fileToUpload = 0
    progress = {}
    progressBar = {}
    fileLength = files.length;
    progressBars.innerHTML = '';
    ([...files]).forEach(uploadFile)
}

const progressBars = document.getElementById('progressBars')


const uploadFile = async (file) => {
    fileToUpload++

    let thisFile = fileToUpload

    let formData = new FormData()
    formData.append('file', file)

    progressBar[thisFile] = document.createElement('div')
    progressBar[thisFile].classList.add('progress-bar')
    progressBars.appendChild(progressBar[thisFile])

    progress[thisFile] = document.createElement('div')
    progress[thisFile].classList.add('progress')
    progress[thisFile].dataset.file = thisFile
    progressBar[thisFile].appendChild(progress[thisFile])


    progressBars.classList.remove('hide')

    const onUploadProgress = event => {
        progress[thisFile].percentCompleted = Math.round((event.loaded * 100) / event.total) + '%';
        progress[thisFile].classList.toggle('active', progress[thisFile].percentCompleted != '100%')
        progress[thisFile].style.width = progress[thisFile].percentCompleted
        if (progress[thisFile].percentCompleted != '100%') {
            progress[thisFile].innerText = progress[thisFile].percentCompleted
        } else {
            progress[thisFile].innerText = `${i18n('file')} ${thisFile} ${i18n('runningMalwareCheck')}`
        }
    };

    if (file.size / 1024 < 2000000) {
        apiUpload({ uri: 'upload', task: 'uploadFile' }, formData, onUploadProgress)
            .then(res => {
                if (res?.data?.error == true) throw res.data
                if (res?.status == 413) throw 'fileSize'
                console.log(res)
                uploadedFiles++

                progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} ${i18n('successUploaded')}`
                progress[thisFile].classList.add('success')


                if (fileLength == uploadedFiles) {
                    drawTable()
                    window.top.postMessage('fileUploaded', '*')
                }
            })
            .catch(e => {
                console.log('UploadError', e)

                uploadedFiles++

                progress[thisFile].style.width = "100%"
                progress[thisFile].classList.add('error')

                if (e.message == 'file exists') {
                    progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} ${i18n('alreadyExists')}`
                } else if (e.message == 'fileSize') {
                    progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} ${i18n('fileToLarge')} 2GB`
                } else if (e.message == 'malicious file') {
                    progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} ${i18n('malwareFound')}`
                } else {
                    progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} - ${i18n('uploadFailed')}`
                }

                if (fileLength == uploadedFiles) {
                    drawTable()
                }
            })
    } else {
        progress[thisFile].style.width = "100%"
        progress[thisFile].classList.add('error')
        progress[thisFile].innerHTML = `${i18n('file')} ${thisFile} ${i18n('fileToLarge')} 2GB`
    }
}