const apiDest = '/api/'
const axios = require('axios');

/**
 * Handles API Requests
 * @param {object} data API Request
 * @returns {object} API return data 
 */
const api = (data) => {
    let uri = data.uri ?? ''
    return axios.post(apiDest + uri, data)
        .then((response) => {
            let res = {}
            res.data = response.data
            res.status = response.status
            console.log('API-Data', res)
            return res
        })
        .catch((err) => {
            let res = {}
            res.data = err.response.data
            res.status = err.response.status
            console.log('API-ERROR', res)
            return res
        })
}

/**
 * Handles API File-Upload-Requests
 * @param {object} data API Request
 * @param {file} file file to send
 * @returns {object} API return data 
 */
const apiUpload = (data, file, dispatch) => {
    let uri = data.uri ?? ''
    let config = {
        onUploadProgress: (progressEvent) => {
            dispatch(progressEvent);
        },
        headers: {
            'Content-Type': file.type
        }
    }

    return axios.post(apiDest + uri, file, config)
        .then((response) => {
            let res = {}
            res.data = response.data
            res.status = response.status
            console.log('API-Data', res)
            return res
        })
        .catch((err) => {
            let res = {}
            res.data = err.response.data
            res.status = err.response.status
            console.log('API-ERROR', res)
            return res
        })
}

/**
 * Handles API File-Download-Requests
 * @param {object} data API Request
 * @param {file} file file to send
 * @returns {object} API return data 
 */
const apiDownload = (data,) => {
    let uri = data.uri ?? ''
    return axios.get(apiDest + uri, {
        responseType: 'arraybuffer'
    })
        .then((res) => {
            console.log('API-Data', res)
            let blob = new Blob([res.data], { type: res.headers.mimetype })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = res.headers.filename
            link.click()
        })
        .catch((err) => {
            let res = {}
            res = err.response.data
            res.status = err.response.status
            console.log('API-ERROR', res)
            return res
        })
}

module.exports = {
    api,
    apiUpload,
    apiDownload
}