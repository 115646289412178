

const redirect = () => {
    if (localStorage.presenterToken != null) return window.location.href = '/app'
    window.location.href = '/'
}

const checkOnline = async () => {
    fetch('/assets/manifest.json')
        .then(res => {
            return redirect()
        })
        .catch(error => {
            setTimeout(() => {
                return checkOnline()
            }, 2000)
        })
}
checkOnline()

document.getElementById('reload').addEventListener('click', () => {
    redirect()
})