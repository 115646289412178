var path = window.location.pathname;
var page = path.split("/").pop();

const menu = document.getElementById('menu')
const menuContent = document.getElementById('menuContent')

menu.addEventListener('click', () => {
    if (menuContent.style.display === 'none') {
        menuContent.style.display = 'block';
        menu.classList.add('active');
    } else {
        menuContent.style.display = 'none';
        menu.classList.remove('active');
    }
});
document.getElementById('content').addEventListener('click', () => {
    if (menuContent.style.display === 'block') {
        menuContent.style.display = 'none';
        menu.classList.remove('active');
    }
});