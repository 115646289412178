const { io } = require('socket.io-client')

const adminURL = 'https://presnetter.de/admin/'
const urlParams = new URLSearchParams(window.location.search);
const timer = document.getElementById('timer')
const dateTime = document.getElementById('dateTime')
const currentTime = document.getElementById('time')
const currentDate = document.getElementById('date')
const timerScreen = document.getElementById('screen')

const timerH = document.getElementById('th')
const timerM = document.getElementById('tm')
const timerS = document.getElementById('ts')
const timerField = document.getElementById('timer')
const timerSeperator = document.getElementsByClassName('dots')

var pin
var eventToken
var projectnumber
var listenOn
var blinkDots
var wssServer

var showTime
var showDate
var timerBlink
var warnTime
var warnTimeUnix
var distance

if (urlParams.has('count-down')) {
    countDownTime = urlParams.get('count-down')
    if (countDownTime != "") {
        timerStart = 1
        timerCount = 'down'
        warnTime = '00:00:00'
        let t = countDownTime.split(':')
        let timeNow = new Date()
        let tNow = timeNow.getTime()
        timerStartTime = tNow
        timerTime = (t[0] - timeNow.getHours()) + ':' + (t[1] - timeNow.getMinutes()) + ':' + (t[2] - timeNow.getSeconds() - 0.5)

        console.log(timerStartTime, timerTime)

        setTime(countDownTime)
        setView()
        getTimer()
    }
} else if (urlParams.has('pin')) {
    pin = urlParams.get('pin')
    if (pin != "") {
        if (getEvent()) { }
    }
} else {
    currentTime.classList.remove('hide')
    currentTime.classList.add('center')
    currentDate.classList.remove('hide')
}


var socket

async function start() {
    socket = io(wssServer)
    socket.on("connect", () => {
        socket.emit("Register", eventToken + '_' + projectnumber)
    });
    getMSG()
    sendHeartbeat()

    socket.on("disconnect", (reason) => {
        console.error(reason)
    })
}

function sendHeartbeat() {
    setInterval(() => {
        wsSend('heartbeat')
    }, 3000)
}



const wsSend = async (command) => {
    let data = { command }
    //if (command !== 'heartbeat') console.log('wss-Out: ', data)
    socket.emit("SendControl", data)
}

async function getMSG() {
    socket.on("SendControl", data => {
        let command = data.command
        let presenter = data.presenter
        let client = data.client
        let controller = data.controller
        let value = data.value

        if (command == "timerSettings") {
            let d = value.split(';').join(',')
            d = JSON.parse(d)
            showTime = d.showTime
            showDate = d.showDate
            timerBlink = d.timerBlink
            warnTime = d.timerWarn
            setView()
        }
        if (command == "reloadTimer" && value == pin) {
            getTimerData()
        }
        if (presenter === listenOn) {
            if (command == "countDownStart" || command == "countDownStop") countDownTimer(command, value)
            if (command == "countUpStart" || command == "countUpStop") countUpTimer(command, value)
            if (command == "setTime") {
                setTime(value)
                getTimerData()
            }
        }
    })
}

timeDate()
setInterval(function () {
    timeDate()
}, 1000)

function timeDate() {
    var timeDate = new Date()
    var date = new Intl.DateTimeFormat('de-DE', { dateStyle: 'full' }).format(timeDate)
    var time = new Intl.DateTimeFormat('de-DE', { timeStyle: 'medium' }).format(timeDate)

    currentDate.innerText = date
    currentTime.innerText = time

}


async function getjson(data) {
    var formBody = new FormData();
    let keys = Object.keys(data)
    for (var i = 0; i < keys.length; i++) {
        formBody.append(keys[i], data[keys[i]]);
    }
    const json = await fetch(adminURL, {
        method: 'POST',
        body: formBody
    });
    let response = await json.json()
    return response;
}

async function getEvent() {
    let data = {
        task: 'getTimer',
        pin: pin,
    }
    let jsonData = await getjson(data)
    let d = jsonData.timerdata
    wssServer = d.wssServer
    eventToken = d.eventtoken
    projectnumber = d.projectnumber

    start()
    getTimerData()
}




function setView() {
    let colorBg = urlParams.has('color-bg') ? urlParams.get('color-bg') : '1C1C1E'
    let colorTinactive = urlParams.has('color-time-inactive') ? urlParams.get('color-time-inactive') : 'ffffff38'
    let colorTactive = urlParams.has('color-time-active') ? urlParams.get('color-time-inactive') : '008000'
    let colorTwarn = urlParams.has('color-time-warn') ? urlParams.get('color-time-warn') : 'ff8d00'
    let colorTtimeout = urlParams.has('color-time-timeout') ? urlParams.get('color-time-timeout') : 'ff0000'
    let colorDate = urlParams.has('color-date-time') ? urlParams.get('color-date-time') : 'ffffffe6'
    let colorScreenBlink = urlParams.has('color-screen-blink') ? urlParams.get('color-screen-blink') : '8b0000'

    if (urlParams.has('nologo')) document.getElementById('nmLogo').style.display = 'none'

    showDate = urlParams.has('date') ? true : showDate
    showDate = urlParams.has('nodate') ? false : showDate
    showTime = urlParams.has('time') ? true : showTime
    showTime = urlParams.has('notime') ? false : showTime
    timerBlink = urlParams.has('blink') ? true : timerBlink
    timerBlink = urlParams.has('noblink') ? false : timerBlink
    warnTime = urlParams.has('warn-time') ? urlParams.get('warn-time') : warnTime

    timer.classList.remove('hide')
    if (showDate && showTime) {
        currentTime.classList.remove('hide')
        currentDate.classList.remove('hide')
        dateTime.classList.remove('center')
    }
    if (showDate && !showTime) {
        currentDate.classList.remove('hide')
        currentTime.classList.add('hide')
        dateTime.classList.add('center')
    }
    if (!showDate && showTime) {
        currentTime.classList.remove('hide')
        currentDate.classList.add('hide')
        dateTime.classList.add('center')
    }
    if (!showDate && !showTime) {
        currentTime.classList.add('hide')
        currentDate.classList.add('hide')
    }

    let w = warnTime.split(':')
    warnTimeUnix = ((1000 * w[0] * 60 * 60) + (1000 * w[1] * 60) + (1000 * w[2]))

    var style = document.createElement('style');
    style.innerHTML = `
    :root {
        --bg-color: #${colorBg};
        --inactive: #${colorTinactive};
        --active: #${colorTactive};
        --warn: #${colorTwarn};
        --time-out: #${colorTtimeout};
        --text-color:#${colorDate};
        --blink: #${colorScreenBlink}; 
    }
    `;
    document.head.appendChild(style);
}



/// TIMERS
var interval
async function getTimerData() {

    let data = {
        task: 'getTimer',
        pin: pin,
    }
    let jsonData = await getjson(data)

    if (await jsonData.error == false) {
        let d = jsonData.timerdata

        listenOn = d.listenOn

        timerTime = d.timer_value
        timerStartTime = d.timer_start_time
        timerStart = d.timer_start
        timerCount = d.count
        warnTime = d.timerWarn
        showDate = parseInt(d.showDate)
        showTime = parseInt(d.showTime)
        timerBlink = parseInt(d.timerBlink)

        getTimer()
        setView()
    }

}

async function getTimer() {
    clearInterval(interval)

    let startTime = new Date()
    let tDefault = '00:00:00'

    let t = timerTime != null ? timerTime.split(':') : tDefault.split(':')

    const StartTimeString = ((1000 * t[0] * 60 * 60) + (1000 * t[1] * 60) + (1000 * t[2]))

    let countDownStartTime = startTime.setTime(parseInt(timerStartTime) + StartTimeString)
    let countUpStartTime = startTime.setTime(parseInt(timerStartTime) - StartTimeString)
    if (timerStart == 1) {
        timerCount == 'down' ? countDownTimer('countDownStart', countDownStartTime) : countUpTimer('countUpStart', countUpStartTime)
    } else {
        timerH.innerText = t[0]
        timerM.innerText = t[1]
        timerS.innerText = t[2]
    }
}

function setTime(value) {
    if (value == 0) {
        timerH.innerText = '00'
        timerM.innerText = '00'
        timerS.innerText = '00'
    } else {
        d = value.split(':')
        timerH.innerText = d[0]
        timerM.innerText = d[1]
        timerS.innerText = d[2]
    }
}

function countDownTimer(cmd, startTime) {
    if (cmd == 'countDownStart') {
        interval = setInterval(function () {
            distance = startTime - new Date().getTime();
            if (warnTimeUnix > distance && distance > 0) {
                timerField.classList.add('timeWarn')
            }
            timerField.classList.remove('inactive')


            if (distance > 0) {
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                timerH.innerText = (hours + '').padStart(2, "0")
                timerM.innerText = (minutes + '').padStart(2, "0")
                timerS.innerText = (seconds + '').padStart(2, "0")

                for (var i = 0; i < timerSeperator.length; ++i) {
                    let seperator = timerSeperator[i]
                    seperator.classList.remove('blink')
                    setTimeout(function () {
                        seperator.classList.add('blink')
                    }, 400);
                }
            } else {
                clearInterval(interval);
                timerH.innerText = '00'
                timerM.innerText = '00'
                timerS.innerText = '00'
                countUpTimer('countUpStart', startTime - 100)
                timerField.classList.remove('timeWarn')
                timerField.classList.add('timeOver')
                for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.remove('blink')
                if (timerBlink == true) {
                    timerScreen.classList.add('blinkScreen')
                } else {
                    timerScreen.classList.remove('blinkScreen')
                }
            }

        }, 1000);

    } else {
        clearInterval(interval);

        if (distance < 0) {
            timerH.innerText = '00'
            timerM.innerText = '00'
            timerS.innerText = '00'
        }

        timerField.classList.remove('timeOver')
        timerField.classList.remove('timeWarn')
        timerField.classList.remove('timeUp')
        timerScreen.classList.remove('blinkScreen')

        timerField.classList.add('inactive')
        for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.remove('blink')
    }
}

function countUpTimer(cmd, startTime) {
    if (cmd == 'countUpStart') {
        timerField.classList.remove('inactive')
        timerField.classList.add('timeUp')

        interval = setInterval(function () {
            var now = new Date().getTime();
            var distance = (now - startTime);
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            timerH.innerText = (hours + '').padStart(2, "0")
            timerM.innerText = (minutes + '').padStart(2, "0")
            timerS.innerText = (seconds + '').padStart(2, "0")
            if (timerBlink == true) {
                if (timerCount == 'down') {
                    timerScreen.classList.add('blinkScreen')
                }
            } else {
                timerScreen.classList.remove('blinkScreen')
            }

        }, 1000);

    } else {
        clearInterval(interval);
        timerField.classList.add('inactive')
        timerField.classList.remove('timeUp')
        for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.remove('blink')
    }
}