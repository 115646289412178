const cookieName = 'pnttr'


const getCookie = () => {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            let res = decodeURIComponent(c.substring(name.length, c.length));
            if (res.length == 0) return
            return JSON.parse(res.substr(2, res.length))
        }
    }
    return;
}

const setCookie = (cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cvalue + ";" + expires + ";path=/";
}

module.exports = {
    getCookie,
    setCookie
}