const { api, apiDownload } = require('./api')
const { i18n } = require('./i18next')

Number.prototype.round = function (places) {
    return +(Math.round(this + "e+" + places) + "e-" + places);
}

const urlParams = new URLSearchParams(window.location.search)

const pin = urlParams.get('pin')
const auth = urlParams.get('auth')
var files
const load = async (pin, auth) => {

    let apiRes = await api({ uri: 'files', task: 'getAllFiles', pin, auth })
    if (apiRes.data) {
        files = apiRes.data
        drawTable(files)
    }
    setTimeout(() => { load(pin, auth) }, 5000)
}
if (pin && auth) {
    load(pin, auth)
}

const lower = (chars) => {
    if (typeof chars == 'string') {
        return chars.toLowerCase()
    }
    return chars
}

const sortJSON = (prop, dir) => {
    return (a, b) => {
        pA = lower(a[prop])
        pB = lower(b[prop])
        if (pA > pB) {
            return dir == 'desc' ? -1 : 1;
        } else if (pA < pB) {
            return dir == 'desc' ? 1 : -1;
        }
        return 0;
    }
}

const sortFiles = () => {
    if (localStorage?.sortFiles) {
        let sortProps = JSON.parse(localStorage?.sortFiles)
        if (sortProps && sortProps.dir) {
            files.sort(sortJSON(sortProps.prop, sortProps.dir))
        }
    }
}


const drawTable = async (files) => {

    if (files.error == true) {
        if (files.message == 'authentication failed') document.getElementById('authFailed').classList.remove('hide')
    } else {
        document.getElementById('authFailed').classList.add('hide')
        document.getElementById('noFiles').classList.toggle('hide', !files.length == 0)
        document.getElementById('presentations').classList.toggle('hide', !files.length > 0)
    }

    let template = document.getElementById('filesTemplate')
    let td = template.content.querySelectorAll('td')
    let tb = document.getElementsByTagName('tbody')

    const clearTable = () => {
        while (tb[0].firstChild) {
            tb[0].removeChild(tb[0].firstChild)
        }
    }
    clearTable()


    if (files.length > 0) {

        sortFiles()

        let i = 0
        files.forEach(file => {

            let uploadTime = new Date(file.uploadTime)
            let filesize = file.size / 1024 > 1000 ? (file.size / 1024 / 1000).round(2) + ' MB' : (file.size / 1024).round(2) + ' KB'

            td[0].textContent = file.presenterName
            td[1].textContent = file.filename
            td[2].textContent = filesize
            td[3].textContent = uploadTime.toLocaleString('de')
            td[4].querySelector('[data-ctrl="download"]').dataset.hash = file.hash
            let clone = document.importNode(template.content, true)
            tb[0].appendChild(clone)
            i++
        })

        document.querySelectorAll('[data-ctrl="download"]').forEach(file => {
            file.addEventListener('click', () => {
                downloadFile(file.dataset.hash)
            })
        })
    }
}

const downloadFile = async (fileHash) => {
    await apiDownload({ uri: 'download/' + fileHash })
}

var sortables = document.querySelectorAll('[data-sort]')
sortables.forEach(el => {
    el.addEventListener('click', () => {
        setSortProps(el)
        setTableSortIndicator()
        drawTable(files)
    })
})

const setSortProps = (col) => {
    let dir = 'asc'
    switch (col.dataset.sortorder) {
        case 'asc':
            dir = 'desc'
            break
        case 'desc':
            dir = 'asc'
            col = sortables[0]
            break
    }
    localStorage.sortFiles = JSON.stringify({ prop: col.dataset.sort, dir })
}

const setTableSortIndicator = () => {
    if (localStorage.sortFiles) {
        let sortProps = JSON.parse(localStorage.sortFiles)
        let col = document.querySelector(`[data-sort="${sortProps.prop}"]`)

        sortables.forEach(el => {
            if (el.dataset.sort == col.dataset.sort) return
            el.dataset.sortorder = null
        })

        switch (sortProps.dir) {
            case 'asc':
                col.dataset.sortorder = 'asc'
                break
            case 'desc':
                col.dataset.sortorder = 'desc'
                break
            default:
                col.dataset.sortorder = null
                sortables[0].dataset.sortorder = 'asc'
        }

    } else {
        sortables[0].dataset.sortorder = 'asc'
    }
}

setTableSortIndicator()